@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./fonts/stylesheet.css');


.css-tgu2la-MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -19px
}
/*helper*/
input::-ms-reveal,input::-ms-clear {
    display:none;
}
body{
   // background: #ededed;
}
//.MuiInput-root,.MuiSelect-select{
//    background: white;
//}
.deepBold{
    font-weight: 500
}
.pac-container {
    //buscador de google map
    z-index: 10000;
}
.pagebreak {
    page-break-before: always;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.bg-red {
    background: #ff7676 !important;
    color: #fff;
}

.bg-red:hover {
    color: #fff;
}

.bg-blue {
    background: #85b4f2 !important;
    color: #fff;
}

.bg-blue:hover {
    color: #fff;
}

.bg-yellow {
    background: #eef157 !important;
    color: #fff;
}

.bg-yellow:hover {
    color: #fff;
}

.bg-green {
    background: #54e69d !important;
    color: #fff;
}

.bg-green:hover {
    color: #fff;
}

.bg-orange {
    background: #ffc36d !important;
    color: #fff;
}
.bg-violet {
    background: #796AEE !important;
    color: #fff;
}
.bg-gray {
    background: #eceeef !important;
}

.bg-white {
    background: #fff !important;
}

.text-red,.text-red-deep * {
    color: #ff3b30 !important;
}

.text-yellow {
    color: #eef157;
}
.text-green {
    color: #11631f;
}

.text-orange {
    color: #f6b911;
}

.text-violet {
    color: #796AEE;
}
.text-blue {
    color: #10376f;
}
.text-gray {
    color: #eceeef;
}
.alignRight{
    text-align: right !important;
}
.text-bold{
    font-weight: 500;
}

html,body,#root,#root > .App{
  height: 100%;
}
body{
  //font-family: 'Arial', sans-serif;
  //  font-size: 15px;
  //font-weight: normal;
    font-family: 'Helvetica Neue', serif;
    font-weight: 300;
    font-style: normal;
  margin: 0;
 color: #3b3b3b;
    // color:rgba(0, 0, 0, 0.87);
}
//table.hasOnclick{
//    tr{
//        td:first-child, th:first-child{
//            padding-left: 16px !important;
//        }
//    }
//}

table:not(.wrap){
    td:not(.comment){
        white-space: nowrap;
    }
    td.comment{
        span{
            display: block;
            min-width: 200px;
        }
        max-width: 200px;
        text-align: justify;
        padding: 0 8px 0 0;
    }
}
table:not(.noHover){
    tbody{
        tr:nth-child(even) {background: #eeeeee}
        tr:nth-child(odd) {background: #fafafa}
        tr:hover {
            background: #d4d4d4;
        }
    }
    &.hasOnclick{
        tr{
            cursor: pointer;
        }
    }
}
fieldset{
  border: 1px solid #bebebe;
}
fieldset legend{
 font-size: 17px;
}

th{
  white-space: nowrap;
}
.contentInner{
   // margin-top: 26px;
    position: relative;

}
.actionCell{
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
}
.actionCell button, .actionCell a{
    margin-left:5px ;
    border-radius:2px;
    box-shadow: none;
    width: 29px;
    height: 25px;
    padding: 0;
    min-width: auto;
    background: #f4f4f4;
}
.actionCell svg{
    width: 17px;
    height: 17px;
}


/* Sweet Alert */
.swal2-container{
    z-index: 1306 !important;
    .swal2-modal{
        border-radius: 2px;
        .swal2-close{
            outline: none;
        }
        .swal2-header{
            .swal2-title{
                font-weight: normal;
            }
        }
        .swal2-actions{
            button{
                border-radius: 2px;
            }
        }
    }
}

.swal-overlay--show-modal .swal-modal,.swal-button{
  border-radius: 2px;
}
.swal-modal.alertNote .swal-text{
    margin: 0;
    padding: 22px 10px;
    font-size: 18px;
}

@media print {
    .d-print-none,.noPrint,.MuiPopover-root{
        display: none !important;
    }
}
@media (max-width: 960px) {
    body{
       // background: white;
    }
    .contentInner{
        padding: 0;
       // padding-bottom:10px;
        position: relative;

    }
    fieldset{
        border: 0;
        border-top: 1px solid #bebebe;
    }
}

@media (min-width: 600px) {
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #cccccc;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b5b5b5;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

}
@media (min-width: 960px) and (max-width: 1200px) {
    .counterItem{
        .number{
            &::before,svg{
                display: none;
            }
        }
    }
}
