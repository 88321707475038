@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Light.woff2') format('woff2'),
        url('HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Medium.woff2') format('woff2'),
        url('HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Bold.woff2') format('woff2'),
        url('HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeue-Roman.woff2') format('woff2'),
        url('HelveticaNeue-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

